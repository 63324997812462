import request from '@/utils/request'

// 登录方法
export function login(username, password) {
  const data = {
    grant_type:'password',
    client_id:'hcWeb',
    client_secret:'hcWeb',
    username,
    password
  }
  return request({
    url: '/hc-basics-auth2/auth/token',
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: data
  })
}



// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/hc-services-system/user/getInfo',
    headers: {
      isToken: true,
      isRepeatSubmit:true
    },
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/hc-basics-auth2/auth/logout',
    headers: {
      isToken: true,
      isRepeatSubmit:true
    },
    method: 'get'
  })
}

