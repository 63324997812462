import { createI18n } from "vue-i18n";
//语言包
import zhCN from './lang/zh-cn'
import enUS from './lang/en'
let language=localStorage.getItem('language') || 'zh_CN'
const i18n=createI18n({
    legacy:false,//设置为false，启用composition API 模式
    locale:language,
    messages:{
        'zh_CN':zhCN,'en_US':enUS,
    }
})
export default i18n