
const data={
    // 规则策略
    ruleStrategy:{
        state:{
            'qiyong':{label:'启用',labelEnUS:'Enable',class:' on'},
            'tingyong':{label:'停用',labelEnUS:'Deactivate',class:''}
        }
       
    },
    // 告警信息
    alarmInfo:{
        state:{
            'weichuzhi':{label:'未处置',labelEnUS:'undisposed',class:'weichuzhi'},
            'chuzhizhong':{label:'处置中',labelEnUS:'disposing',class:'chuzhizhong'},
            'yichuzhi':{label:'已处置',labelEnUS:'disposed',class:'yichuzhi'}
        }
    }
}
export default data