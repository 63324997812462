<template>
  <el-config-provider :locale="zhCn">
    <router-view />
  </el-config-provider>
</template>
<script setup>
import zhCn from "element-plus/es/locale/lang/zh-cn.mjs";
import { ElNotification, ElMessageBox, ElMessage, ElConfigProvider } from "element-plus";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import useUserStore from "@/store/modules/user";
import { ResizeObserverBug } from "@/hook/index.js";
import { useRouter, onBeforeRouteUpdate } from "vue-router";

const router = useRouter();
ResizeObserverBug();
if (
  Object.keys(useUserStore().infoData()).length === 0 &&
  location.pathname !== "/login"
) {
  useUserStore()
    .getInfo()
    .then((res) => {
      if (res === undefined) return;

      if (res.isAdmin == 0 && router.currentRoute.value.meta.authorityUser == undefined) {
        ElMessageBox.alert(t("FormC.ElMessageBox.title5"), t("FormC.ElMessageBox.tips"), {
          confirmButtonText: t("FormC.confirm"),
          callback: (action) => {
            location.href = "/mag/user/index";
          },
        });
      }
    });
} else {
  if (
    (useUserStore().infoData().isAdmin == 0 ||
      useUserStore().infoData().username == "admin") &&
    router.currentRoute.value.meta.authorityUser == undefined
  ) {
    ElMessageBox.alert(t("FormC.ElMessageBox.title5"), t("FormC.ElMessageBox.tips"), {
      confirmButtonText: t("FormC.confirm"),
      callback: (action) => {
        location.href = "/mag/user/index";
      },
    });
  }
}
</script>

<style lang="scss">
#app {
  padding: 0;
  margin: 0;
  height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
}
.mt20{
  margin-top: 20px;

}
.mt10 {
  margin-top: 10px;
}
.http-message {
  z-index: 2999 !important;
}
.el-overlay {
  z-index: 2000 !important;
}
/** 
文字渐变 https://www.jb51.net/css/647191.html
.gradient-text {
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
*/
</style>
