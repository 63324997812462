import i18n from '@/i18n/index'
const t = i18n.global.t
const locale = localStorage.getItem('language')
import {
    throttle
} from "lodash";
import {
    v4 as uuidv4
} from "uuid";
import {
    ref,
    reactive,
    onMounted,
    onBeforeUnmount
} from 'vue'
import router from '@/router/index.js'
import Cookies from "js-cookie";
export const setDataFun = (sqlData, setData) => {
    let arr = []
    for (let i = 0; i < sqlData.length; i++) {
        let aaa = {
            uuid: uuidv4(),
        }
        for (let x in setData) {
            Object.assign(aaa, {
                [x]: sqlData[i][setData[x].sqlName]
            })
        }
        arr.push(aaa)
    }
    return arr
}
export const setImgFun = (arr, setImg) => {
    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < setImg.length; j++) {
            if (arr[i].type == setImg[j].img.sqlName) {
                arr[i].img = setImg[j].img.val
            }
        }
    }
    return arr
}
export const setNumAbbrFun = (num) => {

    let n = parseFloat(num).toFixed(2)
    if (num == 0) {
        n = '0'
    }
    // else if (num > 100) {
    //     n = (num / 100).toFixed(1) + '<span class="numAbbr">' + t('setNumAbbrFun.hundred') + '</span>';

    // } 
    // else if (num > 1000) {
    //     n = (num / 1000).toFixed(1) + '<span class="numAbbr">' + t('setNumAbbrFun.thousand') + '</span>';

    // } else if (num > 10000) {
    //     n = (num / 10000).toFixed(1) + '<span class="numAbbr">' + t('setNumAbbrFun.tenthousand') + '</span>';
    // } 
    else if (num > 100000) {
        n = (num / 100000).toFixed(1) + '<span class="numAbbr">' + t('setNumAbbrFun.Lakh') + '</span>';
    } else if (num > 1000000) {
        n = (num / 1000000).toFixed(1) + '<span class="numAbbr">' + t('setNumAbbrFun.million') + '</span>';
    } else if (num > 10000000) {
        n = (num / 10000000).toFixed(1) + '<span class="numAbbr">' + t('setNumAbbrFun.tenmillion') + '</span>';
    }
    return n

}
import dictionary from '@/dictionary/index'
export const dictionaryFun = (val) => {

    const testCode = `
    function test() {
      return dictionary` + val + `
    }
    return  test()
    `;

    const func = new Function('dictionary', testCode)

    return func(dictionary)


}
export const diffArray = (arr1, arr2) => {
    // 独有的元素
    const uniqueToFirstArray = arr1.filter(item => !arr2.includes(item));
    const uniqueToSecondArray = arr2.filter(item => !arr1.includes(item));

    // 不同的共有元素
    const differentCommonElements = arr1.filter(item =>
        arr2.includes(item) &&
        JSON.stringify(arr1.sort((a, b) => a - b)) !== JSON.stringify(arr2.sort((a, b) => a - b))
    );

    return {
        uniqueToFirstArray,
        uniqueToSecondArray,
        differentCommonElements
    };
}
export const goToUrl = (url) => {
    router.push({
        path: url
    })
}
export const goBack = () => {
    router.go(-1)
}
export const btnShow = (type, authorityType, authorityData) => {
    const meta = router.currentRoute.value.meta ?? ''
    const authority = authorityData ?? meta.authority ?? {
        btn: '',
        tableBtn: ''
    }
    let f = false
    let arr = authority[authorityType] ? authority[authorityType].split(',') : []
    arr.forEach(element => {
        if (element === type) {
            f = true

        }
    });
    return f
}
export const searchProvinceCityArea = (value) => {

    const {
        province,
        city,
        area
    } = value
    let zhongshanshiJSON = JSON.parse(
        JSON.stringify(require("@/assets/json/zhongshanshi.json"))
    );
    let zhongshanshiChild = [];
    zhongshanshiJSON.features.forEach((ze) => {
        const geo_wkt = ze.properties.geo_wkt.split("(")[1].split(")")[0].split(" ");
        zhongshanshiChild.push({
            name: ze.properties.name,
            english: ze.properties.english,
            english1: ze.properties.english1,
            longitude: geo_wkt[0],
            latitude: geo_wkt[1],
            no: ze.properties.unique_id,
            id: ze.properties.unique_id,
            parentId: ze.properties.pid,
        });
    });
    let aaa = JSON.parse(JSON.stringify(require("@/assets/json/provinceCityArea.json")));
    let arr = [];
    aaa.forEach((e) => {
        arr.push(e);
    });
    for (let i = 0; i < arr.length; i++) {
        if (aaa[i].name === "广东省") {
            for (let x = 0; x < aaa[i].children.length; x++) {
                if (aaa[i].children[x].name === "中山市") {
                    aaa[i].children[x] = Object.assign(aaa[i].children[x], {
                        children: zhongshanshiChild,
                    });
                    break;
                }
            }
            break;
        }
    }
    let p = '',
        c = '',
        a = ''

    if (province !== city) {
        p = arr.find((e) => {
            return e.name === province || e.english1 === province || e.english === province
        })

        if (p === undefined) return
        if (city != undefined && city.length !== 0) {
            c = p.children.find((e) => {
                return e.name === city || e.english1 === city || e.english === city
            })
        }

        if (area != undefined && area.length !== 0 && c != undefined) {
            a = c.children.find((e) => {
                return e.name === area || e.english1 === area || e.english === area
            })
        }
        let val = c === undefined || Object.keys(c).length === 0 ? [p.no] : a === undefined || Object.keys(a).length === 0 ? [p.no, c.no] : p.no === c.no ? [c.no, a.no] : [p.no, c.no, a.no]

        return val
    } else {
        p = arr.find((e) => {
            return e.name === province || e.english1 === province || e.english === province
        })

        if (area != undefined && area.length !== 0 && p != undefined) {
            a = p.children.find((e) => {
                return e.name === area || e.english1 === area || e.english === area
            })
        }
        return [p.no, a.no]
    }



}
export const strTrim = (val) => {
    return val.replace(/\s*/g, "")
}
export const keepAliveTableBox = (valObj) => {
    let {
        ref,
        fullPath
    } = valObj
    onMounted(() => {
        let c = sessionStorage.getItem(fullPath);
        if (c === null) return

        ref.value.handleCurrentChange(JSON.parse(c).currentPage);
    });
    onBeforeUnmount(() => {
        let {
            currentPage
        } = ref.value;

        sessionStorage.setItem(fullPath, JSON.stringify({
            currentPage
        }));
    });
}
// 解决报错：ResizeObserver loop completed with undelivered notifications.
const debounce = (callback, delay) => {
    let tid;
    return function(...args) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
            callback.apply(ctx, args);
        }, delay);
    };
};
export const ResizeObserverBug = () => {
    const _ = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ {
        constructor(callback) {
            callback = debounce(callback, 20);
            super(callback);
        }
    };
}
// 要获取当前时间距离今天0点0分的秒数
export const getSecondsSinceMidnight = () => {
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);
    const diff = now - midnight;
    return diff / 1000; // 将毫秒转换成秒
}
export const getSecondsSinceMidnightReutn = (diff) => {
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);
    let d = diff * 1000
    let n = d + midnight.getTime()
    return n
}
export const productIdUploadModel = (item, formItemData, value, disabled) => {

    // let {
    //     res
    // } = item.inputSetting.options;
    // let valueInfo = res.find((e) => {
    //     return e.id == value;
    // });
    // 添加分组选择框
    // if (valueInfo.isGroup == 1) {
    // formItemData.forEach((e, i) => {
    //     if (e.prop === "groupId") {
    //         formItemData.splice(i, 1);
    //     }
    // });


    // formItemData.push({
    //     label: t("mag.device.info.addEditDrawer.groupId"),
    //     prop: "groupId",
    //     inputType: "select",
    //     inputSetting: {
    //         multiple: false,
    //         // 多项目版本
    //         options: {
    //             data: {
    //                 productId: value
    //             },
    //             url: "/hc-services-devices/group/dropDown",
    //             method: "post",
    //             label: "groupName",
    //             value: "id",
    //         },
    //     },
    // });

    // } else {
    //     formItemData.forEach((e, i) => {
    //         if (e.prop === "groupId") {
    //             formItemData.splice(i, 1);
    //         }
    //     });
    // }
    // 添加通道选择框
    // if (valueInfo.isChannel == 1) {
    //     let hascategoryId = formItemData.find((e) => {
    //         return e.prop === "mountChannel";
    //     });

    //     if (hascategoryId == undefined) {
    //         formItemData.push({
    //             label: t("mag.device.info.addEditDrawer.mountChannel"),
    //             prop: "mountChannel",
    //             inputType: "select",
    //             inputSetting: {
    //                 multiple: false,
    //                 disabled,
    //                 options: {
    //                     url: "mountChannel",
    //                     method: "local",
    //                     label: "name",
    //                     value: "code",
    //                 },
    //             },
    //         });
    //     }
    // } else {
    //     formItemData.forEach((e, i) => {
    //         if (e.prop === "mountChannel") {
    //             formItemData.splice(i, 1);
    //         }
    //     });
    // }
    if (value == "7") {
        let hascategoryId = formItemData.find((e) => {
            return e.prop === "mountDevicesId";
        });
        if (hascategoryId == undefined) {
            formItemData.push({
                label: t("mag.device.info.addEditDrawer.mountDevicesId"),
                prop: "mountDevicesId",
                inputType: "select",
                inputSetting: {
                    multiple: false,
                    disabled,
                    options: {
                        url: "/hc-services-devices/device/mountDeviceDropDown",
                        method: "get",
                        label: "deviceId",
                        value: "deviceId",
                    },
                },
            });
        }
    } else {
        formItemData.forEach((e, i) => {
            if (e.prop === "mountDevicesId") {
                formItemData.splice(i, 1);
            }
        });
    }
}
import {
    isChannel
} from '@/api/mag/index.js'
export const productIdUploadModel2 = (item, formItemData, value, disabled) => {

    // let {
    //     res
    // } = item.inputSetting.options;
    // let valueInfo = res.find((e) => {
    //     return e.id == value;
    // });

    // 添加通道选择框
    isChannel(value).then((res) => {
        if (res == undefined) return
        if (res.data) {
            let hascategoryId = formItemData.find((e) => {
                return e.prop === "mountChannel";
            });

            if (hascategoryId == undefined) {
                formItemData.push({
                    label: t("mag.device.info.addEditDrawer.mountChannel"),
                    prop: "mountChannel",
                    inputType: "select",
                    inputSetting: {
                        multiple: false,
                        disabled,
                        options: {
                            url: "mountChannel",
                            method: "local",
                            label: "name",
                            value: "code",
                        },
                    },
                });
            }
        } else {
            formItemData.forEach((e, i) => {
                if (e.prop === "mountChannel") {
                    formItemData.splice(i, 1);
                }
            });
        }
    })

}