import {
  login,
  logout,
  getInfo
} from '@/api/login'
import useSettingStore from '@/store/modules/setting.js'
import Cookies from "js-cookie";
import {
  getToken,
  setToken,
  removeToken,
  getUserInfo,
  removeUserInfo
} from '@/utils/auth'
import {
  defineStore
} from "pinia";
const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    info: getUserInfo()
  }),
  actions: {
    infoData(){
      if(Cookies.get('userInfo')==null){
        return {}
      }else{
        let SelectProject = JSON.parse(sessionStorage.getItem('SelectProject'))
        
        return Object.assign(JSON.parse(Cookies.get('userInfo')),SelectProject)
      }
    },
    // 登录
    login(data) {
      const {
        username,
        password
      } = data
      return new Promise((resolve, reject) => {
        login(username, password).then(res => {

          if (res === undefined) return
          const {
            tokenType,
            token
          } = res.data
          setToken({
            tokenType,
            token
          })
          this.token = {
            tokenType,
            token
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    getInfo() {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {

          if (res === undefined) return
          this.info = {}
          if (res.data.isAdmin == '0') {
            
            
            // this.info = res.data
            Cookies.set("userInfo", JSON.stringify(res.data));
            resolve(res.data)
          } else {
            let SelectProject = JSON.parse(sessionStorage.getItem('SelectProject'))
            if (SelectProject) {
              // this.info = Object.assign(SelectProject, res.data)
              Cookies.set("userInfo", JSON.stringify(Object.assign(SelectProject, res.data)));
              resolve(Object.assign(SelectProject, res.data))
            } else {
              // 多应用跳转到 /selectProject
              if (location.pathname !== '/selectProject') {
                
                location.href = '/selectProject';
              }

            }
          }

        }).catch(error => {
          reject(error)
        })
      })
    },
    //退出系统
    logOut() {
      return new Promise((resolve, reject) => {
        logout().then(res => {
          if (res === undefined) return
          this.token = ''
          sessionStorage.removeItem("/mag/deviceController/index");
          removeToken()
          removeUserInfo()
          Cookies.set("logOutBeforeRouter", location.pathname + location.search);
          resolve()
        })

      })

    },
    logOut2() {
      return new Promise((resolve, reject) => {

        this.token = ''
        removeToken()
        removeUserInfo()
        Cookies.set("logOutBeforeRouter", location.pathname + location.search);
        resolve()


      })

    }
  }
})
export default useUserStore