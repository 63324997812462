import i18n from '@/i18n/index'
const t = i18n.global.t
let language=localStorage.getItem('language') || 'zh_CN'
import axios from 'axios'
import {
  ElNotification,
  ElMessageBox,
  ElMessage
} from 'element-plus'
import {
  getToken
} from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import useUserStore from '@/store/modules/user'
import cache from '@/plugins/cache'


// 是否显示重新登录
export let isRelogin = {
  show: false
};

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Accept-Language'] = language
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/api',

  // 超时
  timeout: 10000
})
let configData = {}
// request拦截器
service.interceptors.request.use(config => {

  configData = Object.assign(configData, config)
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    const {
      tokenType,
      token
    } = JSON.parse(getToken())
    config.headers['Authorization'] = tokenType + ' ' + token // 让每个请求携带自定义token 请根据实际情况自行修改

  }
  // 关于Axios发送Get请求无法添加Content-Type 开始 https://blog.csdn.net/ice_cream__/article/details/135228227
  if (config.method === 'get' && !config.data) {
    config.data = {
      unused: 0
    };
  }
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
  }

  // 关于Axios发送Get请求无法添加Content-Type 结束
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
    const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
    if (requestSize >= limitSize) {
      console.warn(`[${config.url}]: ` + t('request.limitSize'))
      return config;
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url; // 请求地址
      const s_data = sessionObj.data; // 请求数据
      const s_time = sessionObj.time; // 请求时间
      const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = t('request.intervalError');
        console.warn(`[${s_url}]: ` + message)
        // return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  // Promise.reject(error)
})

let errorCode401FunShowNum = 0
const errorCode401Fun = () => {
  if (!isRelogin.show && errorCode401FunShowNum === 0) {
  
    if(getToken()==null){
      location.href = '/login';
      return
    }
    errorCode401FunShowNum = errorCode401FunShowNum + 1
    isRelogin.show = true;
    ElMessageBox.confirm(t('request.errorCode401Fun.content'), t('FormC.ElMessageBox.tips'), {
      confirmButtonText: t('request.errorCode401Fun.confirmButtonText'),
      cancelButtonText: t('FormC.cancel'),
      type: 'warning',
      customClass: 'http-message'
    }).then(() => {


      isRelogin.show = false;
      sessionStorage.removeItem('SelectProject')
      useUserStore().logOut2().then(res => {
        location.href = '/login';
      })



    }).catch(() => {
      errorCode401FunShowNum = 0
      isRelogin.show = false;
    });
  } else {
    // ElNotification({
    //   title: 'Error',
    //   message: '用户名或密码错误，请重新登录。',
    //   type: 'error',
    //   customClass: 'http-message'
    // })
  }
  // return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
}
// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = parseInt(res.data.code) || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.message || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }

    if (code === 401) {
      if (location.href.split('/')[location.href.split('/').length - 1] === 'login') {
        ElNotification({
          title: 'Error',
          message: t('request.errorCode401Fun.passwordError'),
          type: 'error',
          customClass: 'http-message'
        })
      } else {
        isRelogin.show = false;
        errorCode401Fun()
      }
    } else if (code === 500) {
      ElMessage({
        message: msg,
        type: 'error',
        customClass: 'http-message'
      })
      // return Promise.reject(new Error(msg))
    } else if (code === 601) {
      ElMessage({
        message: msg,
        type: 'warning',
        customClass: 'http-message'
      })
      // return Promise.reject(new Error(msg))
    } else if (code === 5104) {
      if (location.href.split('/')[location.href.split('/').length - 1] === 'login') {
        ElNotification({
          title: 'Error',
          message: t('request.errorCode401Fun.passwordError'),
          type: 'error',
          customClass: 'http-message'
        })
      } else {
        isRelogin.show = false;
        errorCode401Fun()
      }

    } else if (code !== 200) {

      if (configData.url.indexOf('/hc-services-asset/index/mapSearch') > -1 || configData.url.indexOf('/hc-services-devices/index/getLightDetailInfo') > -1 || configData.url.indexOf('/hc-seryices-devices/index/getLightPoleDetaiinfo') > -1) {
        ElMessage({
          message: msg,
          type: 'error',
          customClass: 'http-message'
        })

      } else {
        ElNotification({
          title: 'Error',
          message: msg,
          type: 'error',
          customClass: 'http-message'
        })
      }

      // return Promise.reject('error')
    } else {

      return Promise.resolve(res.data)
    }
  },
  error => {

    let {
      message
    } = error;
    if (message == "Network Error") {
      message = t('request.errorCode401Fun.NetworkError');
    } else if (message.includes("timeout")) {
      message = t('request.errorCode401Fun.timeout');
    } else if (message.includes("Request failed with status code")) {


      message = t('request.errorCode401Fun.RequestErrorbefore') + message.substr(message.length - 3) + t('request.errorCode401Fun.RequestErrorafter');

    }
    if (message.indexOf('401') == -1) {

      ElMessage({
        message: message,
        type: 'error',
        customClass: 'http-message',
        duration: 5 * 1000
      })





    }

    if (message.indexOf('401') > -1) {
      if (location.href.split('/')[location.href.split('/').length - 1] === 'login') {
        ElNotification({
          title: 'Error',
          message: t('request.errorCode401Fun.passwordError'),
          type: 'error',
          customClass: 'http-message'
        })
      } else {
        isRelogin.show = false;
        errorCode401Fun()
      }
    }
    // return Promise.reject(error)
  }
)

// 通用下载方法
import {
  ElLoading
} from 'element-plus'
import {
  blobValidate
} from '@/utils/ruoyi'
let downloadLoadingInstance;
import {
  saveAs
} from 'file-saver'
export function download(url, params, filename, config) {
  const {
    tokenType,
    token
  } = JSON.parse(getToken())
  downloadLoadingInstance = ElLoading.service({
    text: t('request.download.Loading'),
    background: "rgba(0, 0, 0, 0.7)",
  })
  return service.get(url, params, {
    transformRequest: [(params) => {
      return tansParams(params)
    }],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: tokenType + ' ' + token
    },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']

      ElMessage({
        message: errMsg,
        type: 'error',
        customClass: 'http-message'
      })
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    ElMessage({
      message: t('request.download.zipError'),
      type: 'error',
      customClass: 'http-message'
    })
    downloadLoadingInstance.close();
  })
}

export default service