//计量单位词：个、只、套 用英文怎么翻译？https://zhidao.baidu.com/question/591341634.html
export default {
    languageTxt: '简体中文',
    operationBox: {
        dropdownName: 'Other functions',
        offOn: 'Toggle Light',
        brightness2: 'Dimming',
        changelnglat: 'Set position',
        changelnglat2: 'Manual adjustment',
        loopControl: 'Loop control'
    },
    setNumAbbrFun: {
        hundred: ' hundred',
        thousand: ' thousand',
        tenthousand: ' ten thousand',
        Lakh: ' Lakh',
        million: ' million',
        tenmillion: ' ten million'
    },
    unit: {
        pcs: 'PCS',
        pcs2: 'PCS'
    },
    citySplit: '省',
    SystemName: 'HECZK Lighting System',
    logOut: 'Exit the system',
    goToIndex: 'Go to index',
    tableColumn: {
        index: 'Number',
        id: 'id',
        devicesName: 'name',
        devicesName2: 'Device Name',
        productName: 'Product Name',
        MountChannelNumber:'Mount channel number',
        RegistrationResults:'Registration results',
        categoryName: 'Classification name',
        devicesRegisterTime: 'Device registration time',
        updateTime: 'Update Time',
        createTime: 'Creation time',
        devicesId: 'Device ID',
        onlineStatus: 'Status',
		rt:'Running time',
        wv: 'Voltage',
        i: 'Current',
        z: 'Main',
        f: 'Deputy',
        p: 'Power',
        e: 'Electricity',
        l: 'Brightness',
        s: 'Color temperature',
        time: 'Duration',
        hardWare: 'Hardware version',
        softWare: 'Software version',
        tmDate: 'Device Clock',
		wlid:'Network ID',
        message: 'Execution result',
        powerOnTime: 'Device power on time',
        fs: 'Sub lamp color temperature',
        zs: 'Main light color temperature',
        fl: 'Secondary light brightness',
        zl: 'Main light brightness',
        fe: 'Auxiliary light electrical energy',
        ze: 'Main light electrical energy',
        fp: 'Sub lamp power',
        zp: 'Main light power',
        fi: 'Auxiliary light current',
        zi: 'Main light current',
        rs: 'RSSI',
        tp: 'temperature',
        ld:'Linkage brightness',
        keepTime:'Delay in minutes',
        times:'Times',
        nickname: 'nickname',
        username: 'user name',
        operation: 'operate',
        sxdy: 'phase voltage',
        sxdl: 'phase current',
        sxgl: 'phase power',
        sxdn: 'phase electricity',
        longitude: 'lng',
        latitude: 'lat',
        assetName:'Lamp pole name',
        mountDevicesId:'Mount device',
        lightType:'Lighting type',
        sumEnergy:'energy consumption'
    },
    request: {
        AlarmInfoOperationData:{
            0:'Device ',
            1:' has no latitude or longitude'
        },
        limitSize: 'The requested data size exceeds the allowed 5M limit and cannot be verified for duplicate submission.',
        intervalError: 'Data is being processed, please do not resubmit',
        download: {
            Loading: 'Downloading data, please wait',
            zipError: 'There was an error downloading the file. Please contact the administrator!'
        },
        errorCode401Fun: {
            content: 'The login status has expired. You can continue to stay on this page or log in again',
            confirmButtonText: 'Login Again',
            passwordError: 'The username or password is incorrect. Please log in again.',
            NetworkError: 'Backend interface connection exception',
            timeout: 'System interface request timeout',
            RequestErrorbefore: 'System Interface',
            RequestErrorafter: 'Exception',



        },
    },
    FormC: {
        edit: 'Edit',
        add: 'New',
        dellMore: 'Batch deletion',
        ElMessageBox: {
            tips: 'System prompt',
            titleEnd: '?',
            title: 'Are you sure you want to delete it?',
            title2: 'Are you sure you want to delete in bulk?',
            title3: 'Please select the data to be deleted in bulk',
            title4: 'Please select a province, city, or town',
            title5: 'Super user is unable to access this page. Click OK to return to the user management page',
            title6: 'Are you sure you want to clear the log?',
            title7: 'Are you sure you want to restart?',
            title8: 'Are you sure you want to reset?',
            titleUser: 'Please select a user before proceeding with the operation',
            titleExport: 'Please select the data to export',
            titleSearch: 'Please enter and search again',

            error: 'Delete failed, please contact the administrator.'
        },
        avatarUploader: {
            loading: 'Uploading...',
            title: 'App Icon',
            title2:'Please click to upload',
            text: 'Only supports images up to 2MB in size',
            error: 'The uploaded image cannot be larger than 2MB!'
        },
        del: 'Delete',
        Close: 'Close',
        cancel: 'Cancel',
        cancel2: 'Canceled',
        operation: 'Operation',
        operation2: 'Group control',
        confirm: 'OK',
        confirm2: 'Confirm',
        placeholder: 'Please select',
        placeholder2: 'Please select a time',
        placeholder3: 'Enter product number to search',
        error: 'Operation failed',
        success: 'Operation successful',
        success2: 'Adjustment successful',
        rules: {
            time: 'Please set the duration',
            size: 'The minimum length is 1 and the maximum is 20',
            ip: 'Please enter IP. Example:127.0.0.1',
            ip2: 'IP type error, please re-enter. Example:127.0.0.1'
        },
        inputDefPlaceholder: 'Please enter',
        Uploader:'Upload file:'
    },
    login: {
        left: {
            LampControl: 'Luminaire Control',
            DistributionCabinet: 'Power Distribution Cabinet',

        },
        form: {
            username: 'Username：',
            password: 'Password：',
            rememberMe: 'Remember Password',
            Login: 'Login'
        },
        rules: {
            username: 'Please fill in your username',
            password: 'Please fill in the password'
        }
    },
    index: {
        header: {
            sunrise: 'sunrise：',
            sunset: 'sunset：',
            enterSys: 'backstage',
            selectProject: 'application',
            regShowFun: 'View operation results',
            regShowBox: 'Operation result',
            regShowBoxOptionBox: 'View details',
            json: {
                lightSource: 'Light control',
                lightSource2: 'light source',
                DistributionCabinet: 'Power Distribution Cabinet',
                LampPost: 'Light pole',

            }
        },
        guangyuanOptionBox: {
            title: 'Batch on/off lights',
            label: 'Group Name:'
        },
        deviceInfo: {
            totalName: 'Device Quantity',
            json: {
                on: 'ON',
                off: 'OFF',
                lightOnOffTime: 'Luminaire ON/OFF Time',
                lightOnRate: 'Luminaire Working Tendency Chart',
                onlineRate: 'Online Rate',
                offlineRate: 'Offline Rate',
                leakageMonitoring: 'Leak Detect'
            }
        },
        ruleStrategy: {
            TitleH2: 'Strategy List',
            on: 'Activation',
            off: 'Deactivate'
        },
        alarmInfo: {
            TitleH2: {
                0: 'Alarm name',
                1: 'Alarm Severity',
                2: 'Alarm List',
                3: 'Worksheet'
            },
            table: {
                warningName: 'name',
                warningLevel: 'grade',
                updateTime: 'time',
                warningStatus: 'state',
                option: 'operate',
                error: 'Please select the lamp post from the middle menu bar and locate it to the alarm device',
                error2: 'Error in alarm information, please contact the administrator'
            },
            json: {
                shebei: 'Device Type',
                yunwei: 'Operation Maintenance',
                nenghao: 'Energy Consumption',
                yiban: 'Regular Alarm',
                jiaoda: 'Minor Alarm',
                yanzhong: 'Major Alarm',
                normal: 'Good'
            }
        },
        workOrderInfo: {
            lightOnOffTimeUndefined: 'No data available',
            lightOnRate: {
                0: 'Not disposed of',
                1: 'Disposed of'
            },
            lightOnOffTime: 'Worksheet Classification',
            lightOnRate: 'Worksheet Disposal Rate'
        },
        log: {
            sys: 'System log',
            device: 'Device log'
        },
        bottom: {
            btnBox: 'Energy Consumption Tendency Chart',
            typeArr: {
                1: 'Nearly 7 days',
                2: 'In the last month',
                3: 'In the last year',
            },
            setData: {
                localEnergy: 'Current Energy Consumption',
                sumEnergy: 'Total Energy Consumption',
                weekEnergy: 'Weekly Energy Consumption',
                monthEnergy: 'Monthly Energy Consumption',
                yearEnergy: 'Annual Energy Consumption'
            }
        },
        baiduMap: {
            totalNumMarkerArrShowFun2: 'Return to the total device count view',
            backAreaCenterTooltip: {
                0: 'Return to the center of the ',
                1: ''
            },
            viewMarkerItem: {
                0: 'View devices in the ',
                1: ' '
            },
            expandMapFun: {
                0: 'Expand View',
                1: 'Restore View'
            },
            heatmapShow: {
                0: 'show heatmap',
                1: 'hide heatmap'
            },
            AlarmInfoOperationDataNull: 'Device information error, please contact administrator.',
            viewMarkerItemUndefined: 'Device positioning error, please reset it in the background management.',
			noCityOrErea:'No Select a province or city, please select first！',
            searchBox: {
                placeholder: 'Please enter the device ID'
            },
            ztList: {
                sxdy: 'Three phase voltage:',
                sxdl: 'Three phase current:',
                sxgl: 'Three phase power:',
                sxdn: 'Three phase electrical energy:',
                height: 'Height:',
                weight: ', Width:',
                length: ', Length:',
                0: 'Offline',
                1: 'Online',
                wv: 'Working voltage:',
                wvUnit: 'V，',
                sl1: {
                    zl: 'Brightness:',
                    zs: 'Color temperature:',
                    zi: 'Working current:',
                    ziUnit: 'A',
                    ze: '， Working energy consumption:',
                    zeUnit: 'KW'
                },
                sl2: {
                    zl: 'Main light brightness:',
                    fl: ', Secondary light brightness:',
                    zs: 'Main light color temperature:',
                    fs: ', color temperature of auxiliary lights:',
                    zi: 'Main light working current:',
                    fi: '， Secondary lamp working current:',
                    ze: '， Main light working energy consumption:',
                    fe: '， Secondary light working energy consumption:'
                }
            },
            operationBox: {
                zl: 'Main brightness',
                fl: 'Sub brightness',
                l: 'Brightness',
                zs: 'Main color temperature',
                fs: 'Sub color temperature',
                s: 'Color temperature',
                time: {
                    error1: 'Please select the minimum time after two minutes',
                    error2: 'Please select within 45 days'
                },
                sl2: {
                    0: 'Main',
                    1: 'Sub'
                },
                minute: 'Minute',
                offOnSwitch: {
                    0: 'OFF',
                    1: 'ON'
                }
            },
            contentBox: {
                denggan: 'Light pole details',
                Alarm: 'Alarm details',
                deviceId: 'Name of light control equipment',
                deviceIds: 'Select device dimming'
            },
            alarmCheckbox: {
                title: 'Alarm Level'
            },
            json: {
                markerArrInfoList: {
                    installPosition: 'Location',
                    attributes: 'Properties',
                    attributes2: 'Voltage and current',
                    denggan: {
                        name: 'Lamp Pole Name',
                        num: 'Number of lighting control devices mounted on the lamp post',
                        id: 'Lamp post ID',
                        time: 'Creation time'
                    },
                    AlarmInfo: {
                        name: 'Device Name',
                        id: 'Device ID',
                        time: 'Alarm time',
                        state: 'Alarm state',
                        chaoshi: 'Does the alarm time out?',
                        type: 'Alarm type',
                        email: 'Alarm push email'
                    }
                }
            }
        },
        selectProject: {
            add: 'Create an application',
            title: 'Switch applications',
            Search: 'Enter keywords to search for applications',
            loadEnd: "It's already ended",
            GIS: 'GIS:',
            createTime: 'createTime:',
            appName: 'apply name',
            appNameRule: 'Please fill in the application name',
            provinceCity: 'GIS',
            provinceCityRule: 'Please select GIS'
        }
    },
    mag: {
        router: {
            assetindex: 'Asset Management',
            assetview: 'View asset grouping',
            deviceinfoTitle: 'Device Management',
            deviceinfo: 'device info details',
            devicegroupMag: 'group management',
            devicegroupMagview: 'group management details',
            alarmindex: 'Alarm Worksheet',
            ruleStrategyindex: 'Rule & Strategy',
            logindex: 'Log',
            deviceController: 'Device Control',
            user: 'User Management',
            deviceSearch: 'Device search',
			communication :'Communication management',
            Setting:'Setting',
            energy:'Energy consumption statistics'
        },
        components: {
            tableBox: {
                description: 'Loading',
                option: 'operation',
                edit: 'Edit',
                delSuccess: 'Delete successful!',
                delError: 'Delete failed, please contact the administrator',
                pagination: {
                    pagesize: "Pcs/Page",
                    total: "Total:",
                    pageClassifier: "Jump",
                }
            },
            addEditDrawer: {
                edit: 'Edit',
                addSuccess: 'Add',
                success: ' success!',
                error: 'Add failed, please contact the administrator'
            },
            searchBox: {
                add: 'Add'
            },
            searchDiv: {
                searchPlaceholder: 'Search',
                PopoverBox: {
                    title: 'Advanced Search'
                }
            },
            leftTree: {
                title: 'Device Tree',
                placeholder: 'Please enter keywords for search'
            }
        },
        tabsList: {
            Light: 'Lamp control',
            Pole: 'Pole',
            Ggd: 'Ggd',
            jcqnb:'Chronological Configuration',
			tgnb:'Dimming time table configuration',
            lightTypeMag:'Lighting type'
        },
        alarm: {
            tabsList: {
                warning: 'Alarm',
                workOrder: 'Work Order'
            },
            btnList: {
                add: 'Create Work Order'
            },
            tableColumn: {
                id: 'Alarm ID',
                warningName: 'Alarm name',
                devicesId: 'Device ID',
                warningType: 'Alarm Type',
                warningLevel: 'Alarm level',
                warningStatus: 'Alarm state',
                warningDeal: 'Alarm disposal',
                updateTime: 'Update Time'
            },
            tableColumn2: {
                warningIds: 'Alarm ID',
                name: 'Work Order Name',
                warningNames: 'Name of associated alarm',
                responsiblePerson: 'Work Order Dispatch List',
                responsibleEmail: 'Work Order Dispatch Email',
                workStatus: 'Work Order Processing Status',
                updateTime: 'Update Time'
            },
            addEditDrawer: {
                warningDealId: 'Alarm disposal:',
                rules: {
                    warningDealId: 'Please select alarm disposal'
                }
            },
            addEditDrawer2: {
                warningIds: 'Alarm name:',
                name: 'Work Order Name:',
                category: 'Work Order Classification:',
                responsiblePerson: 'Work Order Dispatch List:',
                responsibleEmail: 'Work Order Assignment Email:',
                workStatus: 'Work order processing status:',
                rules: {
                    workStatus: 'Please select work order processing status',
                    warningIds: 'Please select alarm name',
                    category: 'Please select work order category',
                    name: 'Please fill in work order name',
                    responsiblePerson: 'Please fill in the work order dispatch list',
                    responsibleEmail: 'Please fill in the work order dispatch email'
                }
            }
        },
        asset: {
            tableColumn: {
                name: 'Name of asset group',
                companyName: 'Company Name',
                assetLightPoleCount: 'Number of poles',
                assetLightCount: 'Number of light controls',
                assetGgdCount:'Number of ggd',
                updateTime: 'Update Time'
            },
            addEditDrawer: {
                name: 'Name of asset group:',
                companyName: 'Company Name:',
                groupIds: 'Select group:',
                rules: {
                    name: 'Please fill in asset portfolio name',
                    companyName: 'Please fill in the unit',

                },
            },
            view: {

                tableColumn: {
                    name: {
                        Ggd: 'Ggd name',
                        Pole: 'Pole name',
                        Light: 'Lamp control name'
                    },
                    deviceId: 'Device ID',
                    installPosition: 'Installation position',
                    installTime: 'Installation time',
                    height: 'height',
                    weight: 'weight',
                    longitude: 'longitude',
                    latitude: 'latitude',
                    updateTime: 'Update Time',
                    material: 'material',
                    poleType: 'Pole type',
                    armType: 'Lamp arm type',
                    lightPoleName: 'Pole name',
                    lampCapType: 'Light source type',
                    lampCapCount: 'Light source count',
                    assetGgdName: 'Affiliation ggd'


                },
                addEditDrawer: {
                    deviceId: 'device:',
                    hlbh: 'Loop number',
                    hlkg: 'Loop switch',
                    name: {
                        Ggd: 'Ggd name:',
                        Pole: 'Pole name:',
                        Light: 'Lamp control name:'
                    },
                    assetGgdId:'Ggd：',
                    provinceCityArea: 'Select provinces and cities (towns):',
                    installPosition: 'Installation location:',
                    installTime: 'Installation time:',
                    height: 'height:',
                    weight: ' weight:',
                    longitude: 'longitude:',
                    latitude: 'latitude:',
                    material: 'material:',
                    poleType: 'pole type:',
                    armType: 'Lamp arm type:',
                    lightPoleId: 'Pole:',
                    lampCapType: 'Light source type:',
                    lampCapCount: 'Light source count:',
                    rules: {
                        deviceId: 'Please select device',
                        name: {
                            Ggd: 'Please fill in the name of power distribution cabinet',
                            Pole: 'Please fill in the lamp post name',
                            Light: 'Please fill in the name of Lamp control'
                        },
                    }
                }
            }
        },
        device: {
            info: {
                searchPlaceholder: 'Please enter device ID',
                btnList: {
                    add: 'Single device registration',
                    addMore: 'Batch registration',
                    addLnglatMore: 'Batch import of latitude and longitude',
                    update: 'Device Upgrade',
                    export: 'export'
                },
                tableColumn: {
                    devicesId: 'Device ID',
                    devicesName: 'Device name',
                    regTime: 'Time of registration',
                    hardWare: 'Firmware version',
                    groupName: 'Group Name',
                    productName: 'Product Name',
                    categoryName: 'Device classification name',
                    onlineStatus: 'Status',
                    updateTime: 'update Time'
                },
                addEditDrawer: {
                    devicesId: 'Device ID:',
                    devicesName: 'Device name:',
                    productId: 'Product:',
                    groupId: 'Device grouping:',
                    groupIdaddOption: {
                        0: 'Ungrouped'
                    },
                    categoryId: 'Classification:',
                    mountDevicesId:'Mount concentrator:',
                    mountChannel:'Channel',
                    onlineStatus: 'Online status:',
                    devicesRegisterTime: 'Device registration time:',
                    softWare: 'Software version:',
                    hardWare: 'Hardware version:',
                    cid: 'Communication card number:',
                    imei: 'Device IMEI:',
                    rules: {
                        devicesId: 'Please fill in the device number',
                        devicesName: 'Please fill in the device name',
                        productId: 'Please select a product',
                        categoryId: 'Please select a category'
                    }
                },
                upDate: {
                    upload__text: {
                        0: 'Drag files here or',
                        1: 'Click to upload'
                    },
                    uploading: 'Uploading',
                    uploadSuccessList: {
                        firmwareName: 'Firmware Name:'
                    },
                    confirm: 'upgrade',
                    success: 'Operation successful, upgrading in progress.',
                    uploadSuccessList0: 'Please upload the firmware file',
                    formItem: {
                        productId: 'Product:',
                        groupId: 'Device:',
                    },
                    tableColumn: {
                        devicesId: 'Device ID',
                        devicesName: 'Device Name',
                        hardWare: 'Firmware version',
                        groupName: 'Group Name'
                    }
                },
                addMore: {
                    modelDown: 'Template download',
                    uploadSuccessBox: {
                        uploadSuccessList: {
                            title: 'registered successfully',
                            title2: 'Successful import of latitude and longitude:',
                            num: 'Device number:'
                        },
                        uploadErrorList: {
                            title: 'Registration failed:',
                            title2: 'Longitude and latitude import failed:',
                        }
                    }
                }
            },
            groupMag: {
                tableColumn: {
                    groupName: 'Group Name',
                    productName: 'Product Name',
                    status: 'status',
                    updateTime: 'Update Time',
                    assetSumName: 'Asset group to which it belongs'
                },
                btnList: {
                    add: 'Add group'
                },
                addEditDrawer: {
                    groupName: 'Group name:',
                    productId: 'Product:',
                    status: 'Status:',
                    assetSumId: 'Asset grouping',
                    rules: {
                        groupName: 'Please fill in the group name',
                        assetSumId: 'Please select asset grouping',
                        productId: 'Please select a product',
                    }
                },
                view: {
                    selection0: 'Please select device operation',
                    TableBox: {
                        add: 'Add grouping configuration',
                        del: 'Cancel grouping configuration'
                    },
                    btnList: {
                        editDevicesGroup: 'Batch group devices'
                    },
                    PopoverformItem: {
                        productId: 'Product:',
                        productIdaddOption: {
                            0: 'all'
                        },
                        categoryId: 'Classification:',
                        assetSumId: 'Asset grouping:'
                    },
                    tableColumn: {
                        devicesId: 'Device ID',
                        devicesName: 'Device Name',
                        productName: 'Product Name',
                        categoryName: 'Device type name',
                        updateTime: 'Device storage time'
                    }
                }
            }
        },
        log: {
            tabsList: {
                device: 'Device Log',
                sys: 'system Log'
            },
            tableColumn: {
                devicesId: 'Device ID',
                createTime: 'Create Time',
                content: 'Log content',
                createNickname: 'Creator nickname',

            },
            btnList: {
                export: 'export',
                clear: 'Clear logs',
                del: 'delete',
                delError: 'Please select a time first'
            },
            formItem: {
                time: 'Time range:',
                type: 'Parameter type:'
            }
        },
        ruleStrategy: {
            tableColumn: {
                name: 'Strategy name',
                type: 'Strategy type',
                runUnit: 'execution unit',
                effectTime: 'Start Time',
                invalidTime: 'End Time',
                runTime: 'execution time',
                targetIdList: 'Device ID',
                status: 'status'
            },
            addEditDrawer: {
                name: 'Strategy name:',
                status: 'status:',
                type: 'Strategy type:',
                productId: 'product:',
                triggerId: 'trigger:',
                judgmentId: 'judgment:',
                disposerId: 'disposer:',
                time: 'Start Time - End Time:',
                runTime: 'execution time:',
                runUnit: 'execution unit:',
                targetType: 'Associated target type:',
                targetIdList: 'Related targets:',
                effectEverydayTime: 'Daily effective time period:',
                effectEverydayTime1: 'Group 1:',
                effectEverydayTime2: 'Group 2:',
                rules: {
                    name: 'Please fill in the strategy name',
                    status: 'Please select a status',
                    type: 'Please select the type',
                    productId: 'Please select a product',
                    triggerId: 'Please select trigger',
                    judgmentId: 'Please select the operation rule',
                    disposerId: 'Please select a disposer',
                    runUnit: 'Please select the execution unit',
                    targetType: 'Please select the associated target type',
                    targetIdList: 'Please select the associated target',

                }
            },
            btnList: {
                add: 'New Strategy',
                add1: 'New event strategy',
                add2: 'Add timing strategy'
            }
        },
        deviceController: {
            ggdViewBox: {
                getDDQKGGTG:'View single lamp group control light perception',
                getDDZR:'View the group control single light on-time schedule',
                getSxjlxs:'View measurement coefficient',
                HLZR:'View Loop On-time Daily Table',
				getDDJR:'View the group control single light chronograph day watch',
                baseInfo: 'Basic information',
				getTgzr:'View the dimming on-time day watch',
				getTgnb:'View the dimming timeline',
				getTgggVO:'View light sensing Settings',
                hideinfoBox: {
                    0: 'Show more',
                    1: 'Hide'
                },
				minute:'min',
                getHlNb: 'View loop chronology',
                JcqStatusListDataTitle: 'Loop state',
				RealTimeData:'Real-time data',
				AlarmWidth:'Alarm width',
                infoLabelList: {
                    4: {
                        id: 'device ID',
                        devicesName: 'Equipment designation',
                        sxdn: 'Three phase electrical energy',
                        sbxh: 'Equipment model',
                        productName: 'Product name',
                        mzxh: 'Module Model',
                        rtcsj: 'Device time',
                        yxsj: 'Run time',
                        jwd: 'Latitude and longitude',
                        jwdLng: 'Longitude',
                        jwdLat: 'Latitude',
                        hgqzsb: 'Current transformer turns ratio',
                        jzqczkg: 'Concentrator operating switch',
						tgbc:'Dimming adaptive compensation',
                        jwsj: 'Local latitude and longitude time',
                        jwpyz: 'Local longitude and latitude time offset value',
                        jwpyzLabel:{
                            0:'sunrise',
                            1:'sunset'
                        },
                        jcqrbms: 'Loop mode',
						tgrbms:'Daily pattern',
						ntgv:'Real-time voltage',
						tglj:'Dimming logic',
                        jcqrbmssortableArrUnit: ' road',
                        sxdy: 'Three phase voltage',
                        sxdl: 'Three phase current',
                        sxgl: 'Three phase electric power',
						sxdlAll: 'Three-phase total current',
						sxdnAll: 'Three-phase total energy',
						sxglAll: 'Three-phase total power',
                        wdgjyz: 'Equipment temperature alarm threshold',
                        temp: 'Equipment temperature',
                        sxdlgjyz: 'Three phase current alarm threshold',
                        sxdlgjyzAmin: 'A-phase min',
                        sxdlgjyzAmax: 'A-phase max',
                        sxdlgjyzBmin: 'B-phase min',
                        sxdlgjyzBmax: 'B-phase max',
                        sxdlgjyzCmin: 'C-phase min',
                        sxdlgjyzCmax: 'C-phase max',
                        sxdygjyz: 'Three phase voltage alarm threshold',
                        sxgjsn: 'Alarm enabling',
						sxgjsnA: 'A-phase Alarm enabling',
						sxgjsnB: 'B-phaseAlarm enabling',
						sxgjsnC: 'C-phaseAlarm enabling',
                        sxgjsnsortableArr: {
                            0: 'Voltage alarm enabling',
                            1: 'Current alarm enable',
                            2: 'Temperature alarm enabled'
                        },
                        hardWare:'Hardware version',
                        softWare:'Software version',
                        hlggsz:'Loop light sensing parameters',
                        hlggszArr:{
                            0:'brightness',
                            1:'return difference'
                        },
                        lxkg:'Polling switch',
                        lxzq:'Polling period',
                        sbsjzq:'Heartbeat cycle',
                        gjzq:'Alarm cycle',
                        ddqkrbms:'Single lamp group control daily meter mode',
                        temp:'Equipment temperature',
						wlid:'Network ID',
						kglsc:'3 switch output',
						kglsr:'Three switch inputs',
						ntgz:'Dimming value',
						dlbh:'Light control short-circuit protection switch',
						tgdls:'Short-circuit condition',
						tgmrv:'Default startup voltage'
                    }
                }
            },
            searchPlaceholder: 'Please fill in the device number to search',
            tabsList: {
                Light: "MQTT",
                sys: 'TCP',
                
            },
            setHlgg: {
                ld: 'Brightness',
                hc: 'Return difference'
            },
			dimTg:{
				tgNum:'Dimming number',
				tgPercent:'Dimming value',
				yf:'month'
			},
            setJwdpyz: {
                rcpyz: 'Sunrise offset value (s)',
                rlpyz: 'Sunset offset value (s)'
            },
            setGJZQ: {
                gjzq: 'Alarm period (minute)'
            },
            setHgqzsb: {
                hgqzsb: 'Current transformer turns ratio'
            },
            setSbsjzq: {
                sbsjzq: 'Beat cycle (s)'
            },
            setLdcs: {
                ldsn: 'Linkage enablement',
                ldys: 'Linkage delay time (seconds)',
                ldcs: 'Linkage timeout (seconds)'
            },
            setJzqczkg: {
                jzqczkg: 'Concentrator operating switch'
            },
            setKglsc: {
                kglsc1: '1 road',
                kglsc2: '2 road',
                kglsc3: '3 road'
            },
            syncHlNb: {
                jcqNum: 'Circuit No',
                jcqyf: 'Month'
            },
            setHlNb: {
                jcqnbId: 'Chronology',
                jcqnbNum: 'Circuit No'
            },
            setHlRm: {
                jcqkg1: '1 road',
                jcqkg2: '2 road',
                jcqkg3: '3 road',
                jcqkg4: '4 road',
                jcqkg5: '5 road',
                jcqkg6: '6 road',
                jcqkg7: '7 road',
                jcqkg8: '8 road'
            },
            setHLZR: {
                todaySeconds:'Time',
                time:'Time (minutes)',
				keepTime:'Time (minutes)',
                group: {
                    0: '',
                    1: ' group',
                    2: ' road'
                },
                zl:'Main brightness',
                zs:'Main color temperature',
                fl:'Sub brightness',
                fs:'Sub color temperature',
                ld:'Linkage brightness',
				ll:'Linkage brightness',
                jcqkg1: '1 road',
                jcqkg2: '2 road',
                jcqkg3: '3 road',
                jcqkg4: '4 road',
                jcqkg5: '5 road',
                jcqkg6: '6 road',
                jcqkg7: '7 road',
                jcqkg8: '8 road'
            },
            setSxjlxs: {
                groupList: {
                    0: "Three phase voltage measurement coefficient",
                    1: "Three phase current measurement coefficient",
                    2: "Three phase power metering coefficient",
                },
                labelList: {
                    A: 'Phase A:',
                    B: 'Phase B:',
                    C: 'Phase C:',
                },
                labelList2: {
                    A: 'Phase A',
                    B: 'Phase B',
                    C: 'Phase C',
                }
            },
            batchIPP: {
                hs: 'Heartbeat time',
                ip: 'IP',
                port: 'Port'
            },
            setLxzq:{
                lxzq:'Polling cycle (minutes)'
            },
            setJzqckg:{
                jzqczkg:'switch'
            },
            tgByGroup:{
                lightTypeId:'Lighting type'
            },
            setDDQKRBMS:{
                ddqkrbms:'Single lamp group control daily meter mode'
            },
            setDDQKGGTG:{
                defaultL:'Default brightness',
                defaultS:'Default color temperature',
                tgStepNum:'Dimming step value',
                tgMin:'Dimming minimum value',
                tgMax:'Dimming maximum value'
            },
            setWSGD:{
                wsgd:'Peripheral power supply'
            },
            setGpnum:{
                lightTypeId:'Lighting type' 
            },
            setTM:{
                tm:''
            },
            setLxkg:{
                lxkg:'polling switch'
            },
            setWlid:{
                wlId:'Network ID'
            },
            fotaCenterLight:{
                subSize:'Subcontract size',
                retryNum:'Retry count',
                intervalTime:'Retry interval (milliseconds)',
                firmwareId:'Upgrade firmware ID'
            },
            error: 'Please select the device before performing the operation',
            view: {
                tabs: {
                    list: 'List',
                    echart: 'Chart',
                    getr:'View daily schedule',
                    getJr:'View the timing schedule',
                    getZr:'Check the on-time schedule'
                },
                getZr:'On time schedule',
                getJr:'Timetable'
            },
            json: {
                btnList: {
                    Light: {
                        jzqzhining:'Concentrator instruction',
                        kzhl:'Control Loop',
                        qkdd:'Group control single light',
                        rjl:'Synchronous measurement coefficient',
                        rd: 'Call for testing',
                        lightOption: 'Dimming',
						dimLightOption:'Dimming',
                        syncTime: 'Calibrate clock',
                        as: 'Read device properties',
                        reb: 'Restart',
                        SIM: 'Read SIM',
                        batchReadIPP: 'Read network parameters',
                        batchIPP: 'Set network parameters',
                        setGP: 'Set latitude and longitude',
                        setLdcs: 'Set linkage parameters',
                        skhl: 'Manual control loop',
                        setHlgg: 'Loop light sensing',
                        setJwdpyz: 'Deviation value of lng and lat',
                        setGJZQ: 'Set alarm period',
                        setHgqzsb: 'Current transformer turns ratio',
                        setKglsc: 'Set 3-channel switching value output',
                        setSbsjzq: 'Set heartbeat cycle',
                        setJzqczkg: 'Concentrator screen enable',
                        syncRgj: 'Alarm parameters of synchronous equipment',
                        syncJwd: 'Synchronization of local longitude and latitude parameters',
                        syncRhlgg: 'Optical sensing parameters of synchronous circuit',
                        setHlNb: 'Set loop year table',
                        syncHlNb: 'Synchronous loop chronology',
                        setHlRm: 'Loop daily meter mode',
                        setHLZR: 'On time schedule setting',
                        getHLZR: 'Synchronized on-time schedule',
                        getSxjlxs: 'Synchronous measurement coefficient',
                        setSxjlxs: 'Set measurement coefficient',
                        syncDDZR: 'Synchronize group control single light on-time schedule',
                        setDDZR: 'Set up a group control single light on-time schedule',
                        setBmd: 'Set whitelist',
                        syncBmd: 'Get whitelist',
                        syncHLZR:'Synchronous Loop Timetable',
                        syncDDQKGGTG:'Synchronize single lamp group control light sensing settings',
                        setLxzq:'Set polling cycle',
                        setJzqckg:'Enable concentrator screen settings',
                        tgByGroup:'Hand controlled group light',
                        setDDQKRBMS:'Set single lamp group control daily meter mode',
                        setDDQKGGTG:'Set single lamp group control light perception',
                        setWSGD:'Set peripheral power supply',
                        reset:'Reset device',
                        setGpnum:'Set lighting type',
                        syncGpnum:'Synchronized lighting type',
                        setTM:'Clock calibration',
                        readTm:'Read device clock',
                        setLxkg:'Set polling switch',
                        syncJr:'Synchronized Timetable',
                        syncZr:'Synchronized on-time schedule',
                        syncDDQKJR:'Synchronized single lamp group control timer',
                        setDDQKJR:'Set single lamp group control timer',
                        setWlid:'Set network ID',
                        syncWlid:'Synchronized network ID',
                        fotaCenterLight:'Concentrator single and double light upgrade',
						setTgrbms:'Set the dimming day watch mode',
						setTgnb:'Set the dimming timeline',
						setTgzr:'Set the dimming time watch',
						setTggg:'Light sensitive dimming Settings',
						setTgbc:'Dimming adaptive compensation',
						jzqczkg:'Concentrator operation switch',
						syncTgnb:'Synchronous dimming timeline',
						syncTgzr:'Synchronous dimming time watch',
						syncTggg:'Synchronous light sensing dimming parameters',
						async8DV:'8Synchronize 8 dimming values',
						async3SI:'3Synchronize the three switch inputs',
						async3SO:'3Synchronize the three switch outputs',
						clearCache:'Clear instruction cache',
						setTglj:'Set the dimming logic',
						save:'Line protection switch'
                    }
                }
            }

        },
        user: {
            status1: 'logout',
            status2: 'Enable',
            status3: 'normal',
            passwordTips: 'Please enter your password',
            passwordTips2: 'Enter your password',
            drawChangePw: 'Change password',
            message: ', Preparing to log in again',
            passwordRule: 'Please enter your password again',
            passwordRule2: 'The two password inputs are inconsistent',
			searchPlaceholder:'Please enter your system nickname',
            tableColumn: {
                username: 'User Name',
                nickname: 'Nickname',
                createTime: 'Creation date',
                appCount: 'Total number of projects',
                devicesCount: 'Total number of devices',
                status: 'State',
                appName: 'Entry name',
                updateTime: 'Creation date',

            },
            addEditDrawer: {
                username: 'User Name:',
                nickname: 'Nickname:',
                userType: 'User type:',
                password: 'Password:',
                contactPhone: 'Contact phone:',
                devicesCount: 'Total number of devices:',
                status: 'Status:',
                rules: {
                    username: 'Please fill in the user name',
                    nickname: 'Please fill in the nickname',
                    userType: 'Please select user type',
                    password: 'Please fill in the initial password',
                    contactPhone1: 'Please enter your phone number',
                    contactPhone2: 'Phone number format error, please re-enter'
                }
            }
        },
        header: {
            oldPassword: 'Old password',
            newPassword: 'New password',
            renew: 'Enter password again',
            oldPasswordRule: 'Please fill in the old password',
            newPasswordRule: 'Please fill in a new password'
        },
        setting:{
            tableColumn:{
                jcqnb:{
                    name:'Chronological name',
                    time:'Date Range',
                    ['1_time']:'Action 1',
                    ['2_time']:'Action 2',
                    ['3_time']:'Action 3',
                    ['4_time']:'Action 4',
                },
                lightTypeMag:{
                    groupNum:'Group number',
                    id:'Lighting Type ID',
                    lightType:'Lighting type',
                    add:'Add lighting types'
                }
            }
        },
		communication:{
			searchPlaceholder:'Access number、ICCID、IMSI',
			btnList:{
				// balanceDisplay:'余额显示',
				// balanceRecharge:'余额充值',
				// updateData:'更新新运营商数据',
			},
			title:{
				paymentMethod:'Payment method',
				wechat:'wechat',
				Alipay:'Alipay',
				totalAmount:'Total amount',
				topUp:'top-up'
			},
			tableColumn:{
				msisdn:'Access number',
				iccid:'ICCID',
				imsi:'IMSI',
				operator:'operator',
				orgName:'Customer name',
				dueDate:'Due date',
				workingCondition:'Card status',
				lifeCycle:'Life cycle',
				remainGprs:'balance(MB)',
				useGprs:'Monthly consumption(MB)',
				totalGprs:'Total package volume(MB)',
				comboName:'Set meal',
				accountBalance:'Account balance',
				waitType:'Silent period type',
				waitDate:'The expiration date of the silence period',
				testType:'Test period type',
				testDate:'Test period expiration date',
				isOrienteer:'orientation',
				totalVoice:'Total voice count (minutes)',
				useVoice:'Voice usage (minutes)',
				remainVoice:'Speech margin (minutes)',
				comboType:'Type of package',
				customerName:'Customer name',
			}
		}
    }
}