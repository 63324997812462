import request from '@/utils/request'

export const batchControlLight = (data) => {
  return request({
    url: '/hc-services-devices/mqttLight/batchControlLight',
    headers: {
      isToken: true,
      isRepeatSubmit: true
    },
    data,
    method: 'post'
  })
}
export const editDevicesGroup = (data) => {
  return request({
    url: '/hc-services-devices/group/editDevicesGroup',
    headers: {
      isToken: true,
      isRepeatSubmit: true
    },
    data,
    method: 'post'
  })
}
export const choseApp=(data)=>{
  return request({
    url: '/hc-services-system/app/choseApp/'+data,
    headers: {
      isToken: true,
      isRepeatSubmit: true
    },
    method: 'get'
  })
}
export const isChannel=(deviceld)=>{
  return request({
    url: '/hc-services-devices/device/isChannel/'+deviceld,
    headers: {
      isToken: true,
      isRepeatSubmit: true
    },
    method: 'get'
  })
}